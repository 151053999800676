$bg: #2a2c3d;
$bg-dark: #262838;
$color: #aaa9aa;
$color-hover: #fff;
$card-title: #353950;
$card-bg: #414562;
$card-color: #9192a0;
$icon: #575757;
$icon-hover: #a6a6a6;

// Цвета разделов
$color_input: #3d41d9;
$color_display: #4e6b4a;
$color_storage: #8d5c06;
$color_network: #af9e0c;
$color_crypto: #903a30;
$color_web3: #3b6e99;
$color_control: #6d6d6d;
$color_math: #5a6d81;
$color_utils: #61533d;
$color_string: #19839a;
$color_object: #718813;
$color_system: #656565;
$color_components: #33625c;
$color_modules: #5d468e;
$color_special: #AA00A2;

@mixin hidescroll() {
    // Cкрыть скролл
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
}

@mixin max-width($screen) {
    @media (max-width: ($screen + "px")) {
        @content;
    }
}

@mixin min-width($screen) {
    @media (min-width: ($screen + "px")) {
        @content;
    }
}