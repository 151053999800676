.modal{
    .modal-card{
        background-color: $bg-dark;
    }

    .MuiButton-containedPrimary{
        background-color: $card-bg;
        box-shadow: none;
        &:hover{
            background-color: #4f526e;
            box-shadow: none;
        }
    }
}

.litegraph.litecontextmenu .litemenu-entry.submenu{
    background-color: $bg-dark !important;

    &:hover:not(.disabled):not(.separator){
        background-color: $card-bg !important;
    }
}

.litegraph .litemenu-title{
    background-color: $card-title;
}

.litegraph.litecontextmenu{
    box-shadow: none !important;
}

.litegraph.litesearchbox{
    background-color: rgba(95, 98, 212, 0.2);
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);

    span{
        color: #fff;
    }
}
.MuiInput-underline::after{
    border-bottom: 1px solid #70a4be !important;
}
.MuiInput-underline::before{
    border-bottom: none !important;
}

* {
    -webkit-tap-highlight-color:transparent;
}