@import './scss/variables';
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");

body {
  max-height: 100vh;
  max-width: 100vw;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.App {
  text-align: center;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.MuiDrawer-paperAnchorDockedBottom {
  border-top: 0px;
}

@import './scss/topmenu.scss';
@import './scss/rightmenu.scss';
@import './scss/graph.scss';