.topmenu {
  background-color: $bg;
  padding: 0 10px;
  height: 46px;
  font-size: 14px;
  display: flex;
  align-items: center;
  min-width: 100vw;
  box-sizing: border-box;

  .topmenu__btn {
    padding: 11px 14px;
    color: $color;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    transition: color 0.1s ease-out;
    user-select: none;

    

    &:nth-last-child(-n+4) .topmenu__btn_submenu{
      right: 0;
      border-radius: 6px 0 6px 6px;
    }

    &:hover {
      color: $color-hover;
    }

    .topmenu__btn_submenu {
      position: absolute;
      background-color: $bg-dark;
      padding: 18px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 0 6px 6px 6px;
      overflow: hidden;
      overflow-y: auto;
      @include hidescroll();
      max-height: calc(100vh - 148px);
      box-sizing: border-box;
      top: calc(100% - 6px);

      .submenu__btn {
        padding: 10px 18px;
        border: 1px solid transparent;
        border-radius: 6px;
        color: $color;
        &:hover{
          color: $color-hover;
        }
      }

      &.input .submenu__btn:hover {
        border-color: $color_input;
      }

      &.display .submenu__btn:hover {
        border-color: $color_display;
      }

      &.storage .submenu__btn:hover {
        border-color: $color_storage;
      }

      &.network .submenu__btn:hover {
        border-color: $color_network;
      }

      &.crypto .submenu__btn:hover {
        border-color: $color_crypto;
      }

      &.web3 .submenu__btn:hover {
        border-color: $color_web3;
      }

      &.control .submenu__btn:hover {
        border-color: $color_control;
      }

      &.math .submenu__btn:hover {
        border-color: $color_math;
      }

      &.utils .submenu__btn:hover {
        border-color: $color_utils;
      }

      &.string .submenu__btn:hover {
        border-color: $color_string;
      }

      &.object .submenu__btn:hover {
        border-color: $color_object;
      }

      &.components .submenu__btn:hover {
        border-color: $color_components;
      }

      &.system .submenu__btn:hover {
        border-color: $color_system;
      }

      &.modules .submenu__btn:hover {
        border-color: $color_modules;
      }

      &.special .submenu__btn:hover {
        border-color: $color_special;
      }

    }

    span {
      margin-right: 4px;

      @include max-width(800){
        margin-right: 0px;
      }

      &.input {
        color: $color_input;
      }

      &.display {
        color: $color_display;
      }

      &.storage {
        color: $color_storage;
      }

      &.network {
        color: $color_network;
      }

      &.crypto {
        color: $color_crypto;
      }

      &.web3 {
        color: $color_web3;
      }

      &.control {
        color: $color_control;
      }

      &.math {
        color: $color_math;
      }

      &.utils {
        color: $color_utils;
      }

      &.string {
        color: $color_string;
      }

      &.object {
        color: $color_object;
      }

      &.components {
        color: $color_components;
      }

      &.system {
        color: $color_system;
      }

      &.modules {
        color: $color_modules;
      }

      &.special {
        color: $color_special;
      }


    }
  }
}