.rightmenu {
    background-color: $bg;
    padding-top: 20px;
    overflow: hidden;
    overflow-y: auto;
    user-select: none;

    .right_toolbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        .top_block {
            flex: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .central_filler {
            flex: 1;
            min-height: 50px;
        }
        .bottom_block {
            flex: 0;
        }
        .bottom_filler {
            flex: 1;
            max-height: 25px;
        }
    }

    span.material-symbols-outlined:not(.drawing) {
        color: #6b6962 !important;
        transition: color 0.1s ease-out;
        @media (hover: hover) {
            &:hover {
                color: #fff !important;
            }
        }
        @media (hover: none) {
            &:active {
                color: #fff !important;
            }
        }
        &.highlighted{
            color: #fff !important;
        }
    }
}
.langSelector{
    position: fixed;
    right:50px ;
    bottom:25px;
    background: $bg-dark;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 6px 6px 0px 6px;
    padding: 18px;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    max-height: calc(100vh - 148px);
    box-sizing: border-box;
    .langWrapper{
        color: #aaa9aa;
        cursor: pointer;
        text-align: left;
        .langItem{
            font-family: Tahoma, sans-serif;
            text-align: left;
            font-size: 14px;
            padding: 10px 18px;
            border: 1px solid transparent;
            border-radius: 6px;
            box-sizing: border-box;
            &:hover{
                border: 1px solid #70a4be;
            }
            &.selected{
                color: #ffffff;
            }
        }
    }
}